export default {
    SELECT_PARTICIPANTS: 'SELECT_PARTICIPANTS',
    MYSELF_ONLY: 'MYSELF_ONLY',
    MINORS_ONLY: 'MINORS_ONLY',
    MYSELF_MINORS: 'MYSELF_MINORS',
    CONTINUE: 'CONTINUE',
    MINORS_COUNT: 'MINORS_COUNT',
    MINORS_AGREEMENT_FOR: 'MINORS_AGREEMENT_FOR',
    YOU_MINORS_AGREEMENT_FOR: 'YOU_MINORS_AGREEMENT_FOR',
    DOCUMENT_HEADER: 'DOCUMENT_HEADER',
    DOCUMENT_HEADER_CONTENT: 'DOCUMENT_HEADER_CONTENT',
    EVENT_HEADER: 'EVENT_HEADER',
    EVENT_NAME: 'EVENT_NAME',
    EVENT_NAME_PLACEHOLDER: 'EVENT_NAME_PLACEHOLDER',
    EVENT_DATE: 'EVENT_DATE',
    EVENT_DATE_PLACEHOLDER: 'EVENT_DATE_PLACEHOLDER',
    PARTICIPANT_HEADER: 'PARTICIPANT_HEADER',
    PARTICIPANT: 'PARTICIPANT',
    PARENT_HEADER: 'PARENT_HEADER',
    ADULT_HEADER: 'ADULT_HEADER',
    EMAIL: 'EMAIL',
    TITLE: 'TITLE',
    FIRST_NAME: 'FIRST_NAME',
    LAST_NAME: 'LAST_NAME',
    NICKNAME: 'NICKNAME',
    MIDDLE_NAME: 'MIDDLE_NAME',
    GENDER: 'GENDER',
    ORGANIZATION: 'ORGANIZATION',
    PHONE: 'PHONE',
    BIRTH_DATE: 'BIRTH_DATE',
    ADDRESS: 'ADDRESS',
    COUNTRY: 'COUNTRY',
    POSTAL_CODE: 'POSTAL_CODE',
    CITY: 'CITY',
    STATE: 'STATE',
    IDENTIFICATION: 'IDENTIFICATION',
    IDENTIFICATION_METHOD: 'IDENTIFICATION_METHOD',
    INVALID_IDENTIFICATION: 'INVALID_IDENTIFICATION',
    EMAIL_SENT_INFORMATION: 'EMAIL_SENT_INFORMATION',
    EMAIL_ADDRESS: 'EMAIL_ADDRESS',
    REQUIRED: 'REQUIRED',
    INVALID_PHONE: 'INVALID_PHONE',
    TOO_OLD: 'TOO_OLD',
    TOO_YOUNG: 'TOO_YOUNG',
    INVALID_BIRTH_DATE: 'INVALID_BIRTH_DATE',
    INVALID_EMAIL: 'INVALID_EMAIL',
    EMAIL_UPDATES: 'EMAIL_UPDATES',
    MINOR_HEADER: 'MINOR_HEADER',
    INVALID_MINOR_BIRTH_DATE: 'INVALID_MINOR_BIRTH_DATE',
    SCROLL_UP: 'SCROLL_UP',
    NEXT: 'NEXT',
    BACK: 'BACK',
    REQUIRED_SIGNATURE: 'REQUIRED_SIGNATURE',
    READ_PLEASE: 'READ_PLEASE',
    BEGIN_SIGNING: 'BEGIN_SIGNING',
    SIGN_NEXT: 'SIGN_NEXT',
    CANCEL: 'CANCEL',
    REQUIRED_SIGNATURE_FOR: 'REQUIRED_SIGNATURE_FOR',
    NOT_SIGNED: 'NOT_SIGNED',
    SIGNED: 'SIGNED',
    INITIAL: 'INITIAL',
    INITIAL_REQUIRED: 'INITIAL_REQUIRED',
    ACCEPT: 'ACCEPT',
    DECLINE: 'DECLINE',
    ACCEPTED: 'ACCEPTED',
    DECLINED: 'DECLINED',
    ACCEPT_DECLINE_REQUIRED: 'ACCEPT_DECLINE_REQUIRED',
    EDIT_INFORMATION: 'EDIT_INFORMATION',
    APPLY_SIGNATURE: 'APPLY_SIGNATURE',
    APPLY_SIGNATURE_OF_MINOR: 'APPLY_SIGNATURE_OF_MINOR',
    SIGNATURE_REQUIRED_TO_APPLY: 'SIGNATURE_REQUIRED_TO_APPLY',
    CERTIFY_INFORMATION: 'CERTIFY_INFORMATION',
    EMAILED_TO: 'EMAILED_TO',
    AGREE_SUBMIT: 'AGREE_SUBMIT',
    SUCCESS: 'SUCCESS',
    DONE: 'DONE',
    DRIVER_LICENCE: 'DRIVER_LICENCE',
    PASSPORT: 'PASSPORT',
    SOCIAL_SECURITY: 'SOCIAL_SECURITY',
    LAST_DIGITS: 'LAST_DIGITS',
    SIGNATURE: 'SIGNATURE',
    TROLL_FREE_PHONE: 'TROLL_FREE_PHONE',
    LOCAL_PHONE: 'LOCAL_PHONE',
    MOBILE_PHONE: 'MOBILE_PHONE',
    HOME_PHONE: 'HOME_PHONE',
    WORK_PHONE: 'WORK_PHONE',
    OTHER_PHONE: 'OTHER_PHONE',
    MONTH: 'MONTH',
    DAY: 'DAY',
    YEAR: 'YEAR',
    SELECT_COUNTRY: 'SELECT_COUNTRY',
    SELECT_STATE: 'SELECT_STATE',
    SELECT: 'SELECT',
    MONTH_REQUIRE: 'MONTH_REQUIRE',
    DAY_REQUIRE: 'DAY_REQUIRE',
    YEAR_REQUIRE: 'YEAR_REQUIRE',
    MIN_MINOR_COUNT: 'MIN_MINOR_COUNT',
    MALE: 'MALE',
    FEMALE: 'FEMALE',
    NOT_SPECIFIED: 'NOT_SPECIFIED',
    POSTAL_CODE_FOR: 'POSTAL_CODE_FOR',
    EDIT_MODAL_CONTENT: 'EDIT_MODAL_CONTENT',
    EDIT: 'EDIT',
    CLOSE: 'CLOSE',
    SELECT_MINOR: 'SELECT_MINOR',
    AGE: 'AGE',
    PARTICIPANT_INITIALS: 'PARTICIPANT_INITIALS',
    PARENT_INITIALS: 'PARENT_INITIALS',
    PARTICIPANT_SIGNATURE: 'PARTICIPANT_SIGNATURE',
    PARENT_SIGNATURE: 'PARENT_SIGNATURE',
    PRIVACY_WAIVERSIGN: 'PRIVACY_WAIVERSIGN',
    SELECTED: 'SELECTED',
    MAXIMUM_COUNT_MINORS: 'MAXIMUM_COUNT_MINORS',
    SUCCESS_INSTRUCTIONS: 'SUCCESS_INSTRUCTIONS'
}