import React from 'react'
import { Field, FormSection } from 'redux-form'
import { Form, Label } from 'semantic-ui-react'
import fields from '../../multilingualism/fields'

export default ({
    id,
    name,
    label,
    required,
    translate
}) => {
    const PHONE_TYPE_OPTIONS = [
        {key: 'Toll-free', text: translate(fields.TROLL_FREE_PHONE), value: 'Toll-free'},
        {key: 'Local', text: translate(fields.LOCAL_PHONE), value: 'Local'},
        {key: 'Mobile', text: translate(fields.MOBILE_PHONE), value: 'Mobile'},
        {key: 'Home', text: translate(fields.HOME_PHONE), value: 'Home'},
        {key: 'Work', text: translate(fields.WORK_PHONE), value: 'Work'},
        {key: 'Other', text: translate(fields.OTHER_PHONE), value: 'Other'}
    ]

    return (<FormSection name={name}>
        <Form.Field required={required} >
            <label htmlFor={`phoneNumber`}>{label}</label>
            <Form.Group>
                <Form.Field width={5}>
                    <Field
                    id={`phoneType`}
                    name={`phoneType`}
                    component={({ input, id, meta: { touched, error } }) =>
                        <div className={!!touched && !!error ? 'error' : ''}>
                            <select
                            {...input}
                                id={id}
                                onChange={({target}) => {
                                    input.onChange(target.value)
                                }}
                                className='ui dropdown'
                            >
                            {
                                PHONE_TYPE_OPTIONS.map(option => (<option key={option.key} value={option.value}>{option.text}</option>))
                            }
                            </select>
                        </div>
                    } />
                </Form.Field>
                <Form.Field width={11}>
                    <Field
                    id={`phoneNumber`}
                    name={`phoneNumber`}
                    component={renderPhoneField}
                    required={required}
                    translate={field => translate(field)}
                     />
                </Form.Field>
            </Form.Group>
        </Form.Field>
    </FormSection>)
}

const setCaretPosition = (target, start) => {
    const num = target.value.split(' ')[0].length
    if (!num) {
        target.setSelectionRange(0, 0)
    } else if (num && num < start) {
        target.setSelectionRange(num, num)
    }
}

const renderPhoneField = ({
    input,
    required,
    meta: {
        touched,
        error
    },
    translate,
    id
}) => {
    const searchParams = new URLSearchParams(document.location.search)
    const prefillData = searchParams.get(id)

    if(prefillData && input.value === ''){
        input.onChange(prefillData)
    }

    return (<Form.Field error={!!touched && !!error}>
        <Form.Input
            {...input}
            id={id}
            type="tel"
            placeholder={'999-999-999'}
            maxLength="15"
            onClick={e => setCaretPosition(e.target, e.target.selectionStart)}
            onBlur={({target}) => {
                input.onBlur(target.value ? target.value.replace(/\D/g,'') : '')
            }}
        />
        {touched && (error && <Label pointing basic color='red' id={`error_${id}`}> {translate(error)} </Label>)}
    </Form.Field>)
}

