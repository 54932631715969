function createActionConstants(name) {
    return {
        REQUEST: `${name}.REQUEST`,
        SUCCESS: `${name}.SUCCESS`,
        FAIL: `${name}.FAIL`,
    }
}

export const PERSON_INFO = createActionConstants('PERSON_INFO')

export const LANGUAGE = createActionConstants('LANGUAGE')

export const ADDITIONAL_INFORMATION = {
    ...createActionConstants('ADDITIONAL_INFORMATION'),
    COUNTRIES: createActionConstants('ADDITIONAL_INFORMATION.COUNTRIES'),
    POSTAL_CODE: createActionConstants('ADDITIONAL_INFORMATION.POSTAL_CODE'),
    BUSINESS_ENTITY: createActionConstants('ADDITIONAL_INFORMATION.BUSINESS_ENTITY')
}

export const SIGNING = {
    INFO: {
        SET: createActionConstants('SIGNING.INFO.SET'),
        CLEAR: createActionConstants('SIGNING.INFO.CLEAR')
    },
    DOCUMENT: {
        ...createActionConstants('SIGNING.DOCUMENT'),
        SINGLE: createActionConstants('SIGNING.DOCUMENT.SINGLE'),
        MULTI: createActionConstants('SIGNING.DOCUMENT.MULTI'),
    },
    PROCESS:  createActionConstants('SIGNING.PROCESS'),
    SESSION: createActionConstants('SIGNING.SESSION'),
    REVOKE: createActionConstants('SIGNING.REVOKE'),
    EVENT: {
        SET: 'SIGNING.EVENT.SET',
        CLEAR: 'SIGNING.EVENT.CLEAR'
    },
    EVENT_FOR_SIGNING: {
        SET: 'SIGNING.EVENT_FOR_SIGNING.SET',
        CLEAR: 'SIGNING.EVENT_FOR_SIGNING.CLEAR'
    },
    DONE: createActionConstants('SIGNING.DONE')
}

export const EVENTS = {
    SET: 'EVENTS.SET',
    CLEAR: 'EVENTS.CLEAR',
    REGISTER_EVENT_DATE_FIELD: createActionConstants('EVENTS.REGISTER_EVENT_DATE_FIELD'),
    REMOVE_EVENT_DATE_FIELD: createActionConstants('EVENTS.REMOVE_EVENT_DATE_FIELD')
}

export const DOCUMENT = createActionConstants('DOCUMENT')
export const PERSONS = createActionConstants('PERSONS')

export const PARTICIPANT_OPTIONS = createActionConstants('PARTICIPANT_OPTIONS')

export const MINORS_DROPDOWN = createActionConstants('MINORS_DROPDOWN')
export const WAIVER_LIST = createActionConstants('WAIVER_LIST')
export const FORM_FIELDS = createActionConstants('FORM_FIELDS')

export const LOADING =createActionConstants('LOADING')
export const LOADED = createActionConstants('LOADED')
export const CANCEL = createActionConstants('CANCEL')
export const UI = {
    ERROR: createActionConstants('UI.ERROR'),
    MODAL: createActionConstants('UI.MODAL')
}
export const LANGUAGE_SETTINGS = {
    SET: 'LANGUAGE_SETTINGS.SET',
    SET_CURRENT: 'LANGUAGE_SETTINGS.SET_CURRENT',
    CLEAR: 'LANGUAGE_SETTINGS.CLEAR'
}

export const EmailOptInSettings = Object.freeze({
    OPTED_OUT_BY_DEFAULT: {
        key: 'optedOutByDefault',
        checkboxLabel: 'Unchecked (opted-out) by default (default)',
        optionLabel: 'Get tips and information by email'
    },
    OPTED_IN_BY_DEFAULT: {
        key: 'optedInByDefault',
        checkboxLabel: 'Checked (opted-in) by default',
        optionLabel: 'Get tips and information by email'
    },
    OPTED_IN_AUTO_AND_HIDDEN: {
        key: 'optedInAutoAndHidden',
        checkboxLabel: 'Hide the checkbox and automatically opt-in',
        optionLabel: 'Get tips and information by email'
    },
    OPTED_IN_REQUIRED: {
        key: 'optedInRequired',
        checkboxLabel: 'Require opt-in agreement to submit the form',
        optionLabel: 'Get tips and information by email'
    }
});
