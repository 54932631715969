// let fetch_origin = 'https://new.waiversign.com';
// console.log('REACT_APP_ENVIRONMENT', process.env.REACT_APP_ENVIRONMENT);
// if(process.env.REACT_APP_ENVIRONMENT === 'production'){
//     fetch_origin = 'https://new.waiversign.com';
// }
// export default fetch_origin
import 'whatwg-fetch'
import { ApiCalls } from './constants'
import { ApiCallTransformer } from './transformer'

export const fetch_origin = `${window.location.origin}${process.env.PUBLIC_URL}`
// export const fetch_origin = `http://localhost:3001${process.env.PUBLIC_URL}`

export async function fetchGet(url) {
    const response = await fetch(`${fetch_origin}/api/${url}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
    })
    return response.json()
}

export async function fetchPost(url, body) {
    const response = await fetch(`${fetch_origin}/api/${url}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify(body),
    })
    return response.json()
}

export async function getPostalCode({ alpha2Country, postalCode }) {
    const zippopotamResponse = await callZippopotam(alpha2Country, postalCode)
    if (!zippopotamResponse || !zippopotamResponse['places']) {
        const openDataSoftRes = await callOpenDataSoft(alpha2Country, postalCode)
        let transformer = new ApiCallTransformer(openDataSoftRes)
        return transformer.toZippopotamusResponse()
    }
    return zippopotamResponse
}

async function callZippopotam(alpha2Country, postalCode) {
    const availableStatuses = [200, 404]
    const response = await fetch(`${ApiCalls.ZIPPOPOTAM_URL}/${alpha2Country}/${postalCode}`)
    if (availableStatuses.indexOf(response.status) !== -1) return response.json()
    return {status: response.status}
}

async function callOpenDataSoft(alpha2Country, postalCode) {
    const availableStatuses = [200, 404]
    const response = await fetch(`${ApiCalls.OPEN_DATA_SOFT_URL}/?dataset=geonames-postal-code&q=${postalCode}&facet=country_code&facet=admin_name1&facet=admin_code1&facet=admin_name2&refine.country_code=${alpha2Country}`)
    if (availableStatuses.indexOf(response.status) !== -1) return response.json()
    return {status: response.status}
}