import { call, put, select, takeLatest } from 'redux-saga/effects'
import { push, replace } from 'react-router-redux'
import { fetchPost } from '../apiCalls/index'
import { SIGNING, LOADING, LOADED, UI, CANCEL, DOCUMENT, PERSONS, WAIVER_LIST, PARTICIPANT_OPTIONS  } from '../constants'
import moment from 'moment'

/**
 * 
 * Submit document
 * 
 * @generator
 * @function submitDocument
 * @param {Object} action - action type and payload
 * @param {string} action.type - SIGNING.DOCUMENT.SINGLE.REQUEST
 * @param {Object} actiom.payload.signingData - electronic signature status
 * @param {string} action.payload.url - current url
 * 
 */

function* submitDocument(action) {

    const state = yield select()
    const { url, signingData } = action.payload

    const searchParams = new URLSearchParams(document.location.search)
    const callbackUrlParam = searchParams.get('callbackUrl')

    const personInfo = state.personsForSigning
    const personAllInfo = state.personInfo
    const partialNotification = state.signingProcess.partialNotification
    const referenceId = state.signingProcess.referenceId
    const callbackUrl = callbackUrlParam ? callbackUrlParam : state.signingProcess.callbackUrl
    const languageSettings = state.languageSettings
    const waiverSign = state.documentForSigning
    const waiverList = state.waiverList
    const event = state.signingProcess.eventForSigning
    const signingSessionId = state.signingSession._id
    const kioskMode = state.signingProcess.kioskMode
    const showUpsellMessage = state.participantOptions.showUpsellMessage
    const entityId = url.split('/')[1] === 'e' ? url.split('/')[2] : ''
    const wsData = { waiverSign, personInfo, referenceId, callbackUrl, event, languageSettings, kioskMode, showUpsellMessage, entityId }

    yield put({
        type: LOADING.SUCCESS
    })

    if (waiverSign._id === waiverList[waiverList.length - 1]._id) {
        wsData.sessionId = signingSessionId
    }

    waiverSign.initials.forEach((initial, idx) => {
        if (initial.acceptDecline) {
            initial.acceptDecline = signingData.initials[idx]
        }
    })

    wsData.waiverSign.customFields.forEach(field => {
        state.formFields.custom.forEach(stateField => {
            if (field.customFieldId === stateField.customFieldId) {
                field.name = stateField.name
            }
        })
    })

    if(partialNotification) {
        try {
            const response = yield call(fetchPost, `document`, wsData)
            if (response.success) {
                const signedWaivers = response.payload
                yield put({
                    type: WAIVER_LIST.SUCCESS,
                    payload: waiverList.map(waiver => {
                        if (waiver._id === waiverSign._id) {
                            waiver.isSigned = true
                        }
                        return waiver
                    })
                })
                yield put({
                    type: SIGNING.DOCUMENT.SUCCESS,
                    payload: personAllInfo.map(personOneInfo => {
                        signedWaivers.forEach(signedWaiver => {
                            if (signedWaiver.personInfo.checkId === personOneInfo.checkId) {
                                personOneInfo.signedWaivers[waiverSign._id] = {
                                    completedDate: moment.utc(signedWaiver.completedDate).toDate(),
                                    signedWaiverId: signedWaiver.signedWaiverId
                                }
                            }
                        })
                        return personOneInfo
                    })
                })
                yield put(replace(url.split('/').slice(0, -1).join('/')))
            } else {
                yield put({
                    type: UI.ERROR.SUCCESS,
                    payload: response.message
                })
            }
            yield put({
                type: LOADED.SUCCESS
            })
        } catch (error) {
            yield put({
                type: WAIVER_LIST.FAIL,
                payload: error
            })
        }
    } else {
        yield put({
            type:WAIVER_LIST.SUCCESS,
            payload: waiverList.map(waiver => {
                if (waiver._id === waiverSign._id) {
                    waiver.isSigned = true
                }
                return waiver
            })
        })
        yield put({
            type: SIGNING.DOCUMENT.SUCCESS,
            payload: personAllInfo.map(personOneInfo => {
                personInfo.forEach(person => {
                    if (personOneInfo.checkId === person.checkId && !person.isGuardian) {
                        personOneInfo.signedWaivers[waiverSign._id] = {
                            completedDate: moment.utc().toDate()
                        }
                    }
                })
                return personOneInfo
            })
        })
        if (waiverList.length !== waiverList.filter(doc => doc.isSigned).length) {
            yield put(replace(url.split('/').slice(0, -1).join('/')))
        } else {
            yield submitAllDocument(url)
            yield put(push(url.split('/signing')[0]))
        }
        yield put({
            type: LOADED.SUCCESS
        })
    }
}

/**
 * 
 * Undo document signing, clear store
 * 
 * @generator
 * @function cancel
 * @param {Object} action - action type and payload
 * @param {string} action.type - CANCEL.REQUEST
 * @param {Object} location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {object} action.payload.match @see {@link https://reacttraining.com/react-router/core/api/match}
 * @param {string} action.payload.match.params.type - endpoint
 * @param {string} action.payload.match.params.ids - documments Ids or session Id
 * 
 * endpoint "session/:sessionId" - return to returnUrl
 * endpoint "doc/documentIds" - return to select participant page
 * 
 */

function* cancel(action) {
    const { match: { params: {type, ids, entityId } }, location } = action.payload

    let url = `/${type}/${ids}${location.search}`
    if(entityId){
        url = `/e/${entityId}/${type}/${ids}${location.search}`
    }
    yield put({
        type: CANCEL.SUCCESS
    })
    yield put(push(url))
}

/**
 * 
 * Redirect to select participant page, clear store
 * 
 * @generator
 * @function done
 * @param {Object} action - action type and payload
 * @param {Object} location @see {@link https://reacttraining.com/react-router/core/api/Route/location-object}
 * @param {object} action.payload.match @see {@link https://reacttraining.com/react-router/core/api/match}
 * 
 */

function* done(action) {  
    let ids
    const idsAndQuery = action.payload.split('/')[2]
    if (idsAndQuery) {
        ids = idsAndQuery.split('?')[0]
    }
    yield put({
        type: CANCEL.SUCCESS
    })
    yield put(push(action.payload))
    yield put({
        type: PARTICIPANT_OPTIONS.REQUEST,
        payload: {
            type: 'doc',
            ids
        }
    })
}

/**
 * 
 * Multi Signing Documents
 * 
 * @generator
 * @function setDocumentForSigning
 * @param {Object} action - action type and payload
 * @param {string} action.type - SIGNING.INFO.SET.REQUEST
 * @param {string} action.payload.url - current url
 * @param {Object} action.payload.wsDoc - Documents for signing waiver
 * 
 */

function* setDocumentForSigning(action) {

    const state =  yield select()
    const { wsDoc, url, location } = action.payload

    const personInfo = state.personInfo

    const personsList = []
    personInfo.forEach(person => {
        const fieldNameForCheck = person.isGuardian ? 'guardianOption' : person.isAdult ? 'adultOption' : 'minorOption'
        const personData = {}
        wsDoc.fields.forEach(field => {
            if (field[fieldNameForCheck] !== 'hidden' && person[field.name]) {
                personData[field.name] = person[field.name]
            }
        })
        wsDoc.customFields.forEach(field => {
            if (field[fieldNameForCheck] !== 'hidden' && person[field.name]) {
                personData[field.name] = person[field.name]
            }
        })
        personData.sendEmail = person.sendEmail
        personData.isAdult = person.isAdult
        personData.isGuardian = person.isGuardian
        personData.checkId = person.checkId
        personData.customFieldList = person.customFieldList
        personData.referenceId = person.referenceId
        personsList.push(personData)
    })
    yield put({
        type: PERSONS.SUCCESS,
        payload: personsList
    })
    yield put({
        type: DOCUMENT.SUCCESS,
        payload: wsDoc
    })
    yield put(push(`${url}/signing${location.search}`))
}

/**
 * 
 * Back to edit participant information
 * 
 * @generator
 * @function backToEditInformation
 * @param {Object} action - action type and payload
 * @param {string} action.type - SIGNING.INFO.CLEAR.REQUEST
 * @param {string} action.payload.pathname - previous page url /doc/documentId/info/myself/1/documentsSignature/signing
 * 
 */

function* backToEditInformation(action) {
    const location = action.payload
    const url = location.pathname.split('/').slice(0, -2).join('/')

    yield put({
        type: DOCUMENT.FAIL,
    })
    yield put({
        type: PERSONS.FAIL
    })
    yield put({
        type: UI.MODAL.FAIL
    })
    yield put(push(`${url}${location.search}`))
}

/**
 * 
 * Sign all documents at once
 * 
 * @generator
 * @function submitAllDocument
 * @param {Object} action - action type and payload
 * @param {string} action.type - SIGNING.DOCUMENT.MULTI.REQUEST
 * @param {string} action.payload - previous page url /doc/documentId/info/myself/1/documentsSignature
 * 
 */

function* submitAllDocument(action) {
    const state =  yield select()

    const personInfo = state.personsForSigning
    const personAllInfo = state.personInfo
    const wsDocs = state.waiverList
    const signingProcessInformation = state.signingProcess
    const signingSessionId = state.signingSession._id

    yield put({
        type: LOADING.SUCCESS
    })

    const response = yield call(fetchPost, `document/all`, {
        personInfo,
        wsDocs,
        referenceId: signingProcessInformation.referenceId,
        callbackUrl: signingProcessInformation.callbackUrl,
        event: signingProcessInformation.eventForSigning,
        sessionId: signingSessionId,
        kioskMode: signingProcessInformation.kioskMode,
        showUpsellMessage: true
    })    
    if (response.success){
        const signedWaivers = response.payload
        yield put({
            type:WAIVER_LIST.SUCCESS,
            payload: wsDocs.map(waiver => {
                if (waiver._id === wsDocs._id) {
                    waiver.isSigned = true
                }
                return waiver
            })
        })
        yield put({
            type: SIGNING.DOCUMENT.SUCCESS,
            payload: personAllInfo.map(personOneInfo => {
                signedWaivers.forEach(signedWaiver => {
                    if (signedWaiver.personInfo.checkId === personOneInfo.checkId) {
                        personOneInfo.signedWaivers[signedWaiver.waiverSign._id].signedWaiverId = signedWaiver.signedWaiverId
                    }
                })
                return personOneInfo
            })
        })
        signingProcessInformation.partialNotification = true
        yield put({
            type: SIGNING.PROCESS.SUCCESS,
            payload: signingProcessInformation
        })
        yield put({
            type: DOCUMENT.FAIL,
        })
        yield put({
            type: PERSONS.FAIL
        })
    }
    yield put({
        type: LOADED.SUCCESS
    })
}

/**
 * 
 * Starts submitDocument on each dispatched SIGNING.DOCUMENT.SINGLE.REQUEST action.
 * Starts submitAllDocument on each dispatched SIGNING.DOCUMENT.MULTI.REQUEST action.
 * Starts cancel on each dispatched CANCEL.REQUEST action.
 * Starts done on each dispatched SIGNING.DONE.REQUEST action.
 * Starts setDocumentForSigning on each dispatched SIGNING.INFO.SET.REQUEST action.
 * Starts backToEditInformation on each dispatched SIGNING.INFO.CLEAR.REQUEST action.
 * 
 * 
 * We can use takeEvery or takeLatest
 *  takeEvery - Allows concurrent fetches of user
 *  takeLatest - Does not allow concurrent fetches
 * 
 * @generator
 * @function signedWaiver
 * @see {@link https://github.com/redux-saga/redux-saga#sagasjs}
 * 
 */

export function* signedWaiver() {
    yield [
        takeLatest(SIGNING.DOCUMENT.SINGLE.REQUEST, submitDocument),
        takeLatest(SIGNING.DOCUMENT.MULTI.REQUEST, submitAllDocument),
        takeLatest(CANCEL.REQUEST, cancel),
        takeLatest(SIGNING.DONE.REQUEST, done),
        takeLatest(SIGNING.INFO.SET.REQUEST, setDocumentForSigning),
        takeLatest(SIGNING.INFO.CLEAR.REQUEST, backToEditInformation)
    ]
}