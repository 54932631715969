import React, {Component} from 'react'
import { Form, Label, Message, Radio, Grid, List } from 'semantic-ui-react'
import getTranslator from '../../multilingualism/getTranslator'
import fields from '../../multilingualism/fields'

class acceptDecline extends Component {
    render () {
        const {
            input,
            apply,
            body,
            meta: { touched, error },
            languageSettings,
            waiverLanguage
        } = this.props
        const translate = getTranslator(languageSettings, waiverLanguage)
        return (<Form.Field>
            <Message id="special-message" error={apply === translate(fields.DECLINED)} info={apply === undefined} positive={apply === translate(fields.ACCEPTED)} style={{display: 'block'}}>
              <Grid verticalAlign="middle" columns="equal" stackable={true}>
                <Grid.Row className="padding-none">
                  <Grid.Column width={14} className="padding">
                    <List className="padding-none">
                      <List.Item>
                        {body}
                      </List.Item>
                    </List>
                  </Grid.Column>
                  {apply === undefined ?
                        <Grid.Column width={2} className="padding-none-left special-button">
                            <Radio
                                className="button-height accept"
                                label={<label className="ui compact mini secondary button not-display-checkbox margin-none accept">{translate(fields.ACCEPT)}</label>}
                                type="radio"
                                name="acceptDecline"
                                value={translate(fields.ACCEPTED)}
                                onChange={(e, data) => {
                                    input.onChange(data.value)
                                    input.onBlur(undefined, true)
                                }}
                            />
                            <Radio
                                className="button-height decline"
                                label={<label className="ui compact mini secondary button not-display-checkbox margin-none decline">{translate(fields.DECLINE)}</label>}
                                type="radio"
                                name="acceptDecline"
                                value={translate(fields.DECLINED)}
                                onChange={(e, data) => {
                                    input.onChange(data.value)
                                    input.onBlur(undefined, true)
                                }}
                            />
                        </Grid.Column>
                        :
                        <Grid.Column width={2} className="padding-none-left special-button">
                            <input
                                className="accept"
                                type="button"
                                value={apply}
                                onClick={() => {
                                    input.onChange('')
                                    input.onBlur(undefined, true)
                                }}
                            />
                        </Grid.Column>
                    }
                </Grid.Row>
              </Grid>
            </Message>
            {touched && (error && <Label pointing basic color='red'> {translate(error)} </Label>)}
        </Form.Field>)
    }
}

export default acceptDecline
